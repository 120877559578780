import { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import PetitionActionBar from "./PetitionActionBar/PetitionActionBar";

const Wrapper = styled.div`
  padding-top: 14px;

  margin-bottom: ${(props) =>
    props.$actionBarHeight}px; // this is the height of sticky bottom, including the border.
  padding-bottom: 12px;

  @media screen and (min-width: 540px) {
    padding-top: 22px;
    font-size: 18px;
  }

  // just eye-balled what is a good page width.
  max-width: 1020px;
`;

const SinglePetitionText = ({ petitionInfo }) => {
  const [actionBarHeight, setActionBarHeight] = useState(null);

  return (
    <Wrapper $actionBarHeight={actionBarHeight}>
      {petitionInfo.fullText}
      <PetitionActionBar
        setActionBarHeight={setActionBarHeight}
        actionBarHeight={actionBarHeight}
        petitionInfo={petitionInfo}
      />
    </Wrapper>
  );
};

SinglePetitionText.propTypes = {
  petitionInfo: PropTypes.object,
};

export default SinglePetitionText;
