import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { isSignInWithEmailLink } from "firebase/auth";
import { fireAuth } from "../../Firebase/firebase";

import ConditionsSlideIn from "./ConditionsSlideIn";
import SignInBoxEmail from "./SignInBoxEmail";
import SignInBoxPhone from "./SignInBoxPhone";
import Button from "./Button";
import CheckContactBox from "./CheckContactBox";

const SubNote = styled.div`
  margin-top: 12px;
  margin-bottom: 18px;
  color: rgba(0, 0, 0, 0.64);
  font-size: 14px;

  @media screen and (min-width: 540px) {
    margin-top: 6px;
    margin-bottom: 14px;
  }
`;

const Wrapper = styled.div`
  padding-top: ${(props) => (props.$useMobileMargins ? "16px" : "44px")};
  padding-bottom: ${(props) => (props.$useMobileMargins ? "24px" : "36px")};

  overflow: ${(props) => (props.$stopScroll ? "hidden" : "auto")};
  position: ${(props) => (props.$stopScroll ? "fixed" : "")};
`;

const SignInBoxWrapper = styled.div`
  margin-top: 24px;

  @media screen and (min-width: 540px) {
    margin-top: 22px;
  }
`;

const Bold = styled.span`
  font-weight: 600;
`;

const LinkButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  padding-bottom: 8px;
  display: flex;
  max-width: 500px;
`;

const ButtonSpace = styled.div`
  min-width: 8px;

  @media screen and (min-width: 540px) {
    min-width: 12px;
  }
`;

const SignIn = () => {
  const navigate = useNavigate();
  const screenWidth = useSelector((state) => state.screenWidth.value);
  const useMobileMargins = screenWidth < 540;

  const selectedVoter = useSelector(
    (state) => state.matchedSelectedVoter.value,
  );
  useEffect(() => {
    if (selectedVoter) {
      navigate("/petitions");
    }
  }, [selectedVoter]);

  const [signInConditionsOpen, setSignInConditionsOpen] = useState(false);
  const [signInMethod, setSignInMethod] = useState(null);
  const [fromEmailRedirect, setFromEmailRedirect] = useState(false);

  // in the case that we've set the recaptcha verifier but we cancelled the phone auth attempt, refresh the page once to clear window.recaptchaVerifier
  useEffect(() => {
    if (window.recaptchaVerifier) {
      window.location.reload(false);
    }
  }, [signInMethod]);

  useEffect(() => {
    if (isSignInWithEmailLink(fireAuth, window.location.href)) {
      setFromEmailRedirect(true);
    } else {
      setFromEmailRedirect(false);
    }
  }, [window.location.href]);

  let boxToShow = <></>;
  if (signInMethod === "phone") {
    boxToShow = (
      <SignInBoxPhone
        onCancel={() => {
          setSignInMethod(null);
        }}
      />
    );
  } else if (signInMethod === "email" || fromEmailRedirect) {
    boxToShow = (
      <SignInBoxEmail
        fromEmailRedirect={fromEmailRedirect}
        onCancel={() => {
          setSignInMethod(null);
        }}
      />
    );
  }

  return (
    <>
      {signInConditionsOpen && (
        <ConditionsSlideIn
          closeConditions={() => {
            setSignInConditionsOpen(false);
          }}
        />
      )}
      <Wrapper
        $useMobileMargins={useMobileMargins}
        $stopScroll={signInConditionsOpen}
      >
        Sign in as a registered voter by{" "}
        <Bold>
          verifying the phone number or email address you’ve registered to vote
          with.
        </Bold>
        <SubNote>
          Only for active California and Florida voters who meet{" "}
          <LinkButton
            onClick={() => {
              setSignInConditionsOpen(true);
            }}
          >
            these conditions
          </LinkButton>
          , including being registered to vote with an SMS-capable phone number
          or email address.
        </SubNote>
        <CheckContactBox
          openSignInConditions={() => {
            setSignInConditionsOpen(true);
          }}
        />
        <SignInBoxWrapper>
          {!signInMethod && !fromEmailRedirect && (
            <ButtonWrapper>
              <Button
                text={"VERIFY PHONE"}
                onClick={() => {
                  setSignInMethod("phone");
                }}
                // $selected={phoneToggled}
              />
              <ButtonSpace />
              <Button
                text={"VERIFY EMAIL"}
                onClick={() => {
                  setSignInMethod("email");
                }}
                // $selected={phoneToggled}
              />
            </ButtonWrapper>
          )}
          {(signInMethod || fromEmailRedirect) && boxToShow}
        </SignInBoxWrapper>
      </Wrapper>
    </>
  );
};

export default SignIn;
