import styled from "styled-components";
import { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import SecondaryButton from "./SecondaryButton";
import { NavLink } from "react-router-dom";

import { TailSpin } from "react-loader-spinner";
import { httpsCallable } from "firebase/functions";
import { fireFunctions } from "../../Firebase/firebase";
import { caVlUpdateDate, flVlUpdateDate } from "./ConditionsSlideIn";

const CssTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== "focuscolor",
})((p) => ({
  // input label when focused
  "& label.Mui-focused": {
    color: p.focuscolor,
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: p.focuscolor,
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: p.focuscolor,
  },
  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: p.focuscolor,
    },
  },
}));

const HideNumberToggleWrapper = styled.div`
  // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***
  // this is all to avoid the up and down arrows while still manatinaing number pad on mobile for numeric input
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  // *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***
`;

const Wrapper = styled.div`
  border: ${(props) =>
    props.$isOpen
      ? "1px solid rgba(0, 0, 0, 0.2)"
      : "1px solid rgba(0, 0, 0, 0.2)"};
  border-radius: 8px;

  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  padding-bottom: 6px;

  // just eye-balled what is a good page width.
  max-width: 1044px;
`;

const OptionTitle = styled.div`
  line-height: 22px;
  cursor: pointer;
`;

const OptionTitleText = styled.span`
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
`;

const CaretWrapper = styled.span`
  padding-right: 8px;
`;

const FullInstructionsWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 10px;
`;

const SpaceBetweenFields = styled.div`
  min-width: 6px;
`;

const FieldLine = styled.div`
  display: flex;
  padding-bottom: 8px;
`;

const SubNote = styled.div`
  margin-top: 2px;
  color: rgba(0, 0, 0, 0.64);
  font-size: 14px;

  margin-bottom: 12px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  opacity: ${(props) => (props.$buttonEnabled ? "1.0" : "0.25")};

  margin-bottom: 12px;
`;

const LinkButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const Bold = styled.span`
  font-weight: 600;
`;

const ListedContactWrapper = styled.div`
  margin-left: 24px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

const SubNotePhones = styled.div`
  margin-top: 4px;
  color: rgba(0, 0, 0, 0.64);
  font-size: 14px;
`;

const CenterLoader = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
`;

const isValidDay = (dayInput, month) => {
  const m31days = [1, 3, 5, 7, 8, 10, 12];
  const m30days = [4, 6, 9, 11];
  if (m31days.includes(month)) {
    if (dayInput <= 31) {
      return true;
    }
  } else if (m30days.includes(month)) {
    if (dayInput <= 30) {
      return true;
    }
  } else if (month === 2) {
    if (dayInput <= 29) {
      return true;
    }
  }
  return false;
};

const formatDob = (input) => {
  // start with just numerical chars
  let dateSoFar = input.replace(/[^0-9]/g, "");
  if (dateSoFar === "") {
    return dateSoFar;
  }

  // check if we need to put a 0 before the first digit. This is true for digits bigger than 1, like 3, where 03 would signify march
  const firstDigit = parseInt(dateSoFar[0]);
  if (firstDigit > 1) {
    dateSoFar = `0${dateSoFar}`;
  }
  if (dateSoFar.length < 2) {
    return dateSoFar;
  }

  // check the month in full (first 2 digits). if the month makes no sense, i.e. 14 b/c it is > 12, add a 0 in the beginning so it makes sense.
  const numericalMonth = parseInt(dateSoFar.slice(0, 2));
  if (numericalMonth > 12) {
    dateSoFar = `0${dateSoFar}`;
  }
  // add the slash after first 2 completed digits
  dateSoFar = `${dateSoFar.slice(0, 2)}/${dateSoFar.slice(
    2,
    dateSoFar.length,
  )}`;
  // return if all I have is first 2 digits and a slash
  if (dateSoFar.length <= 3) {
    return dateSoFar;
  }

  // check if I need to put a 0 before the day
  const ourMonth = parseInt(dateSoFar.slice(0, 2));
  const firstDayDigit = parseInt(dateSoFar[3]);
  // insert 0 before the day if the first digit is more than 3 or more than 2 for feb.
  if (firstDayDigit > 3 || (ourMonth === 2 && firstDayDigit > 2)) {
    dateSoFar = `${dateSoFar.slice(0, 3)}0${dateSoFar.slice(
      3,
      dateSoFar.length,
    )}`;
  }
  // only 1 day digit after first slash
  if (dateSoFar.length < 5) {
    return dateSoFar;
  }

  let numericalDay = parseInt(dateSoFar.slice(3, 5));
  while (dateSoFar.length >= 5 && !isValidDay(numericalDay, ourMonth)) {
    // removing the second digit of the month until it is valid
    dateSoFar = dateSoFar = `${dateSoFar.slice(0, 4)}${dateSoFar.slice(
      5,
      dateSoFar.length,
    )}`;
    // only 1 day digit after first slash
    if (dateSoFar.length < 5) {
      return dateSoFar;
    }
    numericalDay = parseInt(dateSoFar.slice(3, 5));
  }

  // add the slash after first 5 completed digits: mm/dd
  dateSoFar = `${dateSoFar.slice(0, 5)}/${dateSoFar.slice(
    5,
    dateSoFar.length,
  )}`;
  // return if all I have is up to the second slash, return
  if (dateSoFar.length <= 6) {
    return dateSoFar;
  }

  while (dateSoFar[6] !== "1" && dateSoFar[6] !== "2") {
    dateSoFar = dateSoFar = `${dateSoFar.slice(0, 6)}${dateSoFar.slice(
      7,
      dateSoFar.length,
    )}`;
    // if there are no more digits after the second slash, return
    if (dateSoFar.length <= 6) {
      return dateSoFar;
    }
  }
  // return if there is only one digit in the year
  if (dateSoFar.length <= 7) {
    return dateSoFar;
  }

  while (
    !(
      18 <= parseInt(dateSoFar.slice(6, 8)) &&
      parseInt(dateSoFar.slice(6, 8)) <= 20
    )
  ) {
    dateSoFar = dateSoFar = `${dateSoFar.slice(0, 7)}${dateSoFar.slice(
      8,
      dateSoFar.length,
    )}`;
    // if there is only 1 year digit after slash
    if (dateSoFar.length <= 7) {
      return dateSoFar;
    }
  }
  // return if there are only two digit in the year
  if (dateSoFar.length <= 8) {
    return dateSoFar;
  }

  while (
    !(
      180 <= parseInt(dateSoFar.slice(6, 9)) &&
      parseInt(dateSoFar.slice(6, 9)) <= 200
    )
  ) {
    dateSoFar = dateSoFar = `${dateSoFar.slice(0, 8)}${dateSoFar.slice(
      9,
      dateSoFar.length,
    )}`;
    // if there are only 2 year digits after slash
    if (dateSoFar.length <= 8) {
      return dateSoFar;
    }
  }

  return dateSoFar.slice(0, 10);
};

const CheckContactBox = ({ openSignInConditions }) => {
  const [boxIsOpen, setBoxIsOpen] = useState(false);

  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [dob, setDob] = useState("");
  const [zip, setZip] = useState("");

  const [fetchedResults, setFetchedResults] = useState(null);
  const [fetchedState, setFetchedState] = useState(null);

  let resultGuts = <></>;
  if (fetchedResults === "loading") {
    resultGuts = (
      <CenterLoader>
        <TailSpin color="black" height={28} width={28} />
      </CenterLoader>
    );
  } else if (fetchedResults === "error") {
    resultGuts = <>Some error occurred. Please try again.</>;
  } else if (fetchedResults === "invalid state") {
    resultGuts = (
      <>
        This is not a California or Florida Zip Code. We cannot verify this
        voter.
      </>
    );
  } else if (fetchedResults?.data) {
    // quick fix to handle the new format of the backend function by taking Active phones and emails.
    const phones = fetchedResults?.data?.A?.phones || [];
    const emails = fetchedResults?.data?.A?.emails || [];
    // const phones = fetchedResults?.data?.phones || [];
    // const emails = fetchedResults?.data?.emails || [];
    if (phones.length === 0 && emails.length === 0) {
      resultGuts = (
        <>
          <Bold>No phone or email was found</Bold> for this voter. Our{" "}
          {fetchedState === "ca" ? "California" : "Florida"} voter list was last
          updated on {fetchedState === "ca" ? caVlUpdateDate : flVlUpdateDate}.{" "}
          <Bold>
            <NavLink
              target="_blank"
              to={
                fetchedState === "ca"
                  ? "https://registertovote.ca.gov/"
                  : "https://registertovoteflorida.gov/home"
              }
              style={{ color: "black" }}
            >
              Update your voter registration
            </NavLink>
          </Bold>{" "}
          to include an SMS-capable phone or email, and meet{" "}
          <LinkButton onClick={openSignInConditions}>
            these conditions
          </LinkButton>
          , to sign in after our next voter list update.
        </>
      );
    } else if (phones.length > 0 || emails.length > 0) {
      const singleOnlyFound = phones.length + emails.length === 1;
      const phonesOnly = emails.length === 0;

      resultGuts = (
        <>
          The following {singleOnlyFound ? "was" : "were"} found:
          <br />
          <ListedContactWrapper>
            {phones.map((phone, i) => (
              <div key={`${phone}${i}`}>
                <Bold>{phone}</Bold>
                {/* {phone} */}
                <br />
              </div>
            ))}
            {emails.map((email, i) => (
              <div key={`${email}${i}`}>
                <Bold>{email}</Bold>
                {/* {email} */}
                <br />
              </div>
            ))}
          </ListedContactWrapper>
          {phonesOnly ? (
            <>
              Verify via SMS below.
              <SubNotePhones>
                If {singleOnlyFound ? "this" : "these"} phone{" "}
                {singleOnlyFound ? "number" : "numbers"} cannot receive texts,{" "}
                <NavLink
                  target="_blank"
                  to={
                    fetchedState === "ca"
                      ? "https://registertovote.ca.gov/"
                      : "https://registertovoteflorida.gov/home"
                  }
                  style={{ color: "rgba(0, 0, 0, 0.64)" }}
                >
                  update your voter registration
                </NavLink>{" "}
                to include an SMS-capable phone or email to sign in with after
                our next voter list update.
              </SubNotePhones>
            </>
          ) : (
            <>
              Verify {singleOnlyFound ? "it" : "one of them"} below to sign in.
            </>
          )}
        </>
      );
    }
  }

  let buttonEnabled = false;
  if (
    first.length > 0 &&
    last.length > 0 &&
    dob.length === 10 &&
    zip.length === 5
  ) {
    buttonEnabled = true;
  }

  return (
    <Wrapper $isOpen={boxIsOpen}>
      <OptionTitle
        onClick={() => {
          setBoxIsOpen(!boxIsOpen);
        }}
      >
        <CaretWrapper>
          {boxIsOpen ? (
            <FontAwesomeIcon icon={faCaretDown} />
          ) : (
            <FontAwesomeIcon icon={faCaretRight} />
          )}
        </CaretWrapper>
        <OptionTitleText>Check your phone/email on file.</OptionTitleText>
      </OptionTitle>
      {boxIsOpen && (
        <>
          <FullInstructionsWrapper>
            Enter your information to see the phone/email associated with your
            voter registration on file.
          </FullInstructionsWrapper>
          <FieldLine>
            <CssTextField
              InputLabelProps={{
                style: { fontFamily: `"Inter", sans-serif` },
              }}
              InputProps={{
                style: { fontFamily: `"Inter", sans-serif` },
              }}
              focuscolor="black"
              label="First Name"
              variant="outlined"
              type="text"
              name="first"
              size="small"
              value={first}
              onChange={(event) => {
                setFirst(event.target.value.toUpperCase());
              }}
            />
            <SpaceBetweenFields />
            <CssTextField
              InputLabelProps={{
                style: { fontFamily: `"Inter", sans-serif` },
              }}
              InputProps={{
                style: { fontFamily: `"Inter", sans-serif` },
              }}
              focuscolor="black"
              label="Last Name"
              variant="outlined"
              type="text"
              name="last"
              size="small"
              value={last}
              onChange={(event) => {
                setLast(event.target.value.toUpperCase());
              }}
            />
          </FieldLine>
          <FieldLine>
            <CssTextField
              InputLabelProps={{
                style: { fontFamily: `"Inter", sans-serif` },
              }}
              InputProps={{
                style: { fontFamily: `"Inter", sans-serif` },
              }}
              focuscolor="black"
              label="Date of Birth"
              // helperText="MM/DD/YYYY"
              variant="outlined"
              type="text"
              name="dob"
              size="small"
              value={dob}
              onChange={(event) => {
                const newVal = event.target.value;
                // in most cases, we want to process the new value in the text field
                let toProcess = newVal;
                // in the case that the new val is identical to the current val with just the last / backspaced, we want to delete the actual numerical char
                if (`${newVal}/` === dob) {
                  // removing the last numerical char
                  toProcess = newVal.slice(0, newVal.length - 1);
                }
                // process the dob to have the right slashes and day/month/years that make sense.
                setDob(formatDob(toProcess));
              }}
            />
            <SpaceBetweenFields />
            <HideNumberToggleWrapper>
              <CssTextField
                InputLabelProps={{
                  style: { fontFamily: `"Inter", sans-serif` },
                }}
                InputProps={{
                  style: { fontFamily: `"Inter", sans-serif` },
                }}
                focuscolor="black"
                label="Zip Code"
                variant="outlined"
                type="number"
                name="zip"
                size="small"
                value={zip}
                onChange={(event) => {
                  const numsOnly = event.target.value.replace(/[^0-9]/g, "");
                  setZip(numsOnly.slice(0, 5));
                }}
              />
            </HideNumberToggleWrapper>
          </FieldLine>
          <SubNote>
            Check spelling. Should match your voter info –{" "}
            <NavLink
              target="_blank"
              to="https://voterstatus.sos.ca.gov/"
              style={{ color: "rgba(0, 0, 0, 0.64)" }}
            >
              CA
            </NavLink>
            ,{" "}
            <NavLink
              target="_blank"
              to="https://registration.elections.myflorida.com/en/CheckVoterStatus/Index"
              style={{ color: "rgba(0, 0, 0, 0.64)" }}
            >
              FL
            </NavLink>
            . Omit suffix/title from name. Enter birthdate as MM/DD/YYYY.
          </SubNote>
          <ButtonWrapper $buttonEnabled={buttonEnabled}>
            <SecondaryButton
              enabled={buttonEnabled}
              text={"FIND PHONE/EMAIL"}
              onClick={() => {
                if (buttonEnabled) {
                  const zipInt = parseInt(zip);
                  const isCaZip = zipInt >= 90000 && zipInt <= 96699;
                  const isFlZip = zipInt >= 32000 && zipInt <= 34999;
                  if (!isCaZip && !isFlZip) {
                    setFetchedResults("invalid state");
                  } else {
                    const getVoterContact = httpsCallable(
                      fireFunctions,
                      "getVoterContact",
                    );
                    setFetchedResults("loading");
                    getVoterContact({
                      first: first,
                      last: last,
                      zip: zip,
                      dob: dob,
                    })
                      .then((resp) => {
                        setFetchedState(isCaZip ? "ca" : "fl");
                        setFetchedResults(resp);
                      })
                      .catch(() => {
                        setFetchedResults("error");
                      });
                  }
                }
              }}
            />
          </ButtonWrapper>
          {resultGuts}
        </>
      )}
    </Wrapper>
  );
};

CheckContactBox.propTypes = {
  openSignInConditions: PropTypes.func,
};

export default CheckContactBox;
