import { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import ConditionsSlideIn from "./SignIn/ConditionsSlideIn";
import { NavLink } from "react-router-dom";

const Wrapper = styled.div`
  padding-top: ${(props) => (props.$useMobileMargins ? "16px" : "54px")};
  padding-bottom: ${(props) => (props.$useMobileMargins ? "28px" : "54px")};

  // just eye-balled what is a good page width.
  max-width: 680px;

  overflow: ${(props) => (props.$stopScroll ? "hidden" : "auto")};
  position: ${(props) => (props.$stopScroll ? "fixed" : "")};

  // font-size: 18px;
`;

const LinkButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const Title = styled.div`
  padding-top: 4px;
  font-size: ${(props) => (props.$useMobileMargins ? "20px" : "22px")};
  font-weight: 500;
  padding-bottom: 20px;
`;

const SubTitle = styled.div`
  padding-top: 48px;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 20px;
`;

const NoBold = styled.span`
  font-weight: 400;
`;

const About = () => {
  const [signInConditionsOpen, setSignInConditionsOpen] = useState(false);
  const screenWidth = useSelector((state) => state.screenWidth.value);

  // matches with home and petition. slightly smaller cutoff than results
  const useMobileMargins = screenWidth < 540;

  const userInfo = useSelector((state) => state.userInfo.value);
  const isLoggedIn = userInfo?.uid && true;

  return (
    <>
      {signInConditionsOpen && (
        <ConditionsSlideIn
          closeConditions={() => {
            setSignInConditionsOpen(false);
          }}
        />
      )}
      <Wrapper
        $useMobileMargins={useMobileMargins}
        $stopScroll={signInConditionsOpen}
      >
        <Title $useMobileMargins={useMobileMargins}>
          Verified Voice is a different kind of petition{" "}
          <NoBold>– only verified registered voters can participate.</NoBold>
        </Title>
        This means that the overall results of any petition send a clear signal
        to elected officials of true constituent preference.
        <SubTitle>Voter Verification</SubTitle>
        To verify your voter registration and voice your opinion on Verified
        Voice, you must{" "}
        {isLoggedIn ? (
          "verify the phone number or email address"
        ) : (
          <NavLink to="/signin" style={{ color: "black" }}>
            verify the phone number or email address
          </NavLink>
        )}{" "}
        {`you've`} registered to vote with. This process only works for active
        California and Florida voters registered with an SMS-capable phone
        number or email address who meet{" "}
        <LinkButton
          onClick={() => {
            setSignInConditionsOpen(true);
          }}
        >
          these conditions
        </LinkButton>
        .
        <br />
        <br />
        Since we routinely update our voter lists from the state, registering to
        vote with an SMS-capable phone number or email address now can allow you
        to sign into Verified Voice in the future.
        <SubTitle $useMobileMargins={useMobileMargins}>Contact</SubTitle>
        We aim to strengthen our democracy by making political advocacy direct
        and convenient. Please contact info@verifiedvoice.org for any questions,
        comments, or concerns.
        <br />
        <br />
        Verified Voice is run by Rahul Patel in Mountain View, California.
      </Wrapper>
    </>
  );
};

export default About;
