// this file will have some constant values for styles that are used in multiple components across the app.
// they can be changed once here.

// minimum screen width at which we start using desktop margins for SIDE margins
export const desktopMarginsCutoff = 540;

// actual px values of margins
// this is ONLY FOR LEFT AND RIGHT SIDE MARGINS.
export const mobileMarginVal = 16;
export const desktopMarginVal = 24;
