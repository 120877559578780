import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  border-radius: 4px;
  text-align: center;
  color: ${(props) => (props.$isPrimary ? "white" : "black")};
  background: ${(props) => (props.$isPrimary ? "black" : "white")};
  font-size: 12px;
  letter-spacing: 0.05em;
  font-weight: 600;

  height: 34px;
  line-height: 34px;
  padding-left: 32px;
  padding-right: 32px;

  transition-duration: 0.25s;
  transition-timing-function: ease-in;
  cursor: pointer;
`;

const FormButton = ({ text, onClick, isPrimary }) => {
  return (
    <Wrapper onClick={() => onClick()} $isPrimary={isPrimary}>
      {text}{" "}
    </Wrapper>
  );
};

FormButton.propTypes = {
  isPrimary: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default FormButton;
