import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { signOut } from "firebase/auth";
import { fireAuth } from "../../Firebase/firebase";

import { setMatchedSelectedVoter } from "../../Redux/matchedSelectedVoterSlice";
import {
  desktopMarginVal,
  desktopMarginsCutoff,
  mobileMarginVal,
} from "../../styleConstants";

const Wrapper = styled.div`
  // to not make it too wide on desktop view.
  max-width: 720px;

  padding-left: ${mobileMarginVal}px;
  padding-right: ${mobileMarginVal}px;

  @media screen and (min-width: ${desktopMarginsCutoff}px) {
    padding-left: ${desktopMarginVal}px;
    padding-right: ${desktopMarginVal}px;
  }
`;

const InstructionsWrapper = styled.div`
  position: sticky;
  top: 0;
  background: white;
  padding-bottom: 16px;
  border-bottom: 1px solid #e5e5e5;

  padding-top: 16px;
  @media screen and (min-width: 540px) {
    padding-top: 54px;
  }
`;

const SignOutWrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;

  color: rgba(0, 0, 0, 0.5);
  font-style: italic;
`;

const VoterTile = styled.div`
  font-weight: 500;
  font-size: 18px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 8px;
  border-radius: 8px;
  margin-top: 8px;

  cursor: pointer;
  &:hover {
    border: 1px solid black;
  }
`;

const SubNote = styled.div`
  // margin-top: 8px;
  color: rgba(0, 0, 0, 0.64);
  font-size: 14px;
  font-weight: 400;
`;

const LinkButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
`;

const MultiMatchGuts = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInfo.value);
  const matchedVoters = useSelector((state) => state.matchedAllVoters.value);
  const phone = userInfo.phone;
  const contactType = phone ? "phone number" : "email address";

  // this function will help us sort voters alphabetically by full name and address.
  const compareVoters = (v1, v2) => {
    const v1str = `${v1?.first}${v1?.last}${v1?.suffix}${v1?.city}${v1?.state}`;
    const v2str = `${v2?.first}${v2?.last}${v2?.suffix}${v2?.city}${v2?.state}`;
    if (v1str < v2str) {
      return -1;
    }
    if (v1str > v2str) {
      return 1;
    }
    return 0;
  };

  const votersSorted = [...matchedVoters].sort(compareVoters);

  return (
    <Wrapper>
      <InstructionsWrapper>
        Your {contactType} matches multiple voters. Select the one you are:
      </InstructionsWrapper>
      {votersSorted.map((voter, i) => (
        <VoterTile
          key={i}
          onClick={() => {
            dispatch(setMatchedSelectedVoter(matchedVoters[i]));
          }}
        >
          {voter?.first} {voter?.last} {voter?.suffix}
          {/* {voter.suffix && ` ${voter.suffix}`}{" "} */}
          <SubNote>
            of {voter?.city}, {voter.state.toUpperCase()}
          </SubNote>
        </VoterTile>
      ))}
      <SignOutWrapper>
        If none of these are you, you can{" "}
        <LinkButton
          onClick={() => {
            signOut(fireAuth);
          }}
        >
          sign out
        </LinkButton>{" "}
        to try a different phone/email or keep browsing.
      </SignOutWrapper>
    </Wrapper>
  );
};

export default MultiMatchGuts;
