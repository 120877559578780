import styled from "styled-components";
import { useState } from "react";
import { useSelector } from "react-redux";

import { signOut } from "firebase/auth";
import { fireAuth } from "../../../Firebase/firebase";

import NavbarMobileButton from "../../Navbar/NavbarMobileButton";
import ConditionsSlideIn from "../../../Pages/SignIn/ConditionsSlideIn";
import SignOutButtonDesktop from "./SignOutButtonDesktop";
import {
  desktopMarginVal,
  desktopMarginsCutoff,
  mobileMarginVal,
} from "../../../styleConstants";

const Wrapper = styled.div`
  // to not make it too long on desktop view, matches about page.
  max-width: 640px;

  // for the side margins
  padding-left: ${mobileMarginVal}px;
  padding-right: ${mobileMarginVal}px;
  @media screen and (min-width: ${desktopMarginsCutoff}px) {
    padding-left: ${desktopMarginVal}px;
    padding-right: ${desktopMarginVal}px;
  }

  padding-top: 24px;
  padding-bottom: 16px;
  @media screen and (min-width: 540px) {
    padding-top: 54px;
    padding-bottom: 24px;
  }

  overflow-y: ${(props) => (props.$stopScroll ? "hidden" : "auto")};
  overflow-x: hidden;
  position: ${(props) => (props.$stopScroll ? "fixed" : "")};
`;

const LinkButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const NoMatchGuts = () => {
  const userInfo = useSelector((state) => state.userInfo.value);
  const phone = userInfo.phone;
  const email = userInfo.email;
  const userContact = phone || email;
  const contactType = phone ? "phone number" : "email address";

  const [signInConditionsOpen, setSignInConditionsOpen] = useState(false);

  const screenWidth = useSelector((state) => state.screenWidth.value);

  // matches with home and petition. slightly smaller cutoff than results
  const useMobileMargins = screenWidth < 540;

  return (
    <>
      {signInConditionsOpen && (
        <ConditionsSlideIn
          closeConditions={() => {
            setSignInConditionsOpen(false);
          }}
        />
      )}
      <Wrapper
        $useMobileMargins={useMobileMargins}
        $stopScroll={signInConditionsOpen}
      >
        Your verified {contactType}, {userContact}, was not found in our
        California or Florida voter lists. For this reason, we cannot verify
        your voter registration.
        <br />
        <br /> This does not necessarily mean you are not registered to vote!
        You may be registered outside of California or Florida, after our last
        voter list update, or without the {contactType} you just verified. See{" "}
        <LinkButton
          onClick={() => {
            setSignInConditionsOpen(true);
          }}
        >
          what is required
        </LinkButton>{" "}
        to sign into Verified Voice as a verified registered voter.
        <br />
        <br />
        Be sure to register or to update your voter registration to include an
        SMS-capable phone number or email address in order to sign into Verified
        Voice.
        <br />
        <br />
        For now, please sign out in order to verify a different phone number or
        email address, or to continue browsing Verified Voice.
        <br />
        <br />
        <br />
        {useMobileMargins ? (
          <NavbarMobileButton
            text="SIGN OUT"
            onClick={() => {
              signOut(fireAuth);
            }}
          />
        ) : (
          <SignOutButtonDesktop
            text="SIGN OUT"
            onClick={() => {
              signOut(fireAuth);
            }}
          />
        )}
        <br />
      </Wrapper>
    </>
  );
};

export default NoMatchGuts;
