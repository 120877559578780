import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  border-radius: 4px;
  border: 1px solid black;
  text-align: center;
  color: white;
  background: black;
  font-size: 12px;
  letter-spacing: 0.05em;
  font-weight: 500;

  height: 30px;
  line-height: 30px;
  width: 100%;

  transition-duration: 0.25s;
  transition-timing-function: ease-in;
  cursor: pointer;
  &:hover {
    background: white;
    color: black;
    border: 1px solid black;
  }
`;

const IconWrapper = styled.span`
  margin-left: 6px;
`;

const Button = ({ text, onClick }) => {
  return (
    <Wrapper onClick={() => onClick()}>
      {text}
      <IconWrapper>
        <FontAwesomeIcon icon={faArrowRight} />
      </IconWrapper>
    </Wrapper>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default Button;
