import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  border-radius: 4px;
  border: 1px solid black;
  text-align: center;
  background: white;
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 12px;
  letter-spacing: 0.05em;
  font-weight: 600;

  height: 32px;
  line-height: 32px;
  width: 80%;
  max-width: 400px;

  transition-duration: 0.25s;
  transition-timing-function: ease-in;
  cursor: ${(props) => (props.$enabled ? "pointer" : "not-allowed")};
`;

const IconWrapper = styled.span`
  margin-right: 6px;
`;

const SecondaryButton = ({ enabled, text, onClick }) => {
  return (
    <Wrapper
      $enabled={enabled}
      onClick={() => {
        if (enabled) {
          onClick();
        }
      }}
    >
      <IconWrapper>
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </IconWrapper>
      {text}
    </Wrapper>
  );
};

SecondaryButton.propTypes = {
  enabled: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default SecondaryButton;
